import { Component, ElementRef, ViewChild } from '@angular/core';
import { OrderService } from '../order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '../models/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-userapp-referral-reward',
  templateUrl: './userapp-referral-reward.component.html',
  styleUrls: ['./userapp-referral-reward.component.sass']
})
export class UserappReferralRewardComponent {
  nextPageShowHide = '1';
  store_id: string;
  storeId: string;
  id: string;
  referralKey: string;
  queryParam1: string;
  getData: any;
  form: FormGroup;
  formVali: FormGroup;
  errorMessage: any = '';
  refereeGetsDollar: any;
  referrerGetsDollar: any;
  idGet: any;
  hash: string;
  phoneNumber: any;
  createdAt: any;
  expiresAt: any;
  redeemId: any;
  redeemedAmount: any;
  store;
  offerCatalogSharableLink;
  isLoading = true;
  ShowOPT: any;
  errorMessage1: string;
  formOTP: FormGroup;
  is_verified: false;
  errorMessage2: string;
  otpValues: string[] = ['', '', '', '', '', ''];
  nextVerifyShowHide: boolean = false;
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  enteredOTP: string;
  hideHeader: boolean = false;
  submitted = false;
  referralForm!: FormGroup;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,

  ) {
    this.referralKey = this.route.snapshot.paramMap.get('referralKey');
    this.queryParam1 = this.route.snapshot.paramMap.get('id');
    // this.storeId = this.route.snapshot.paramMap.get('storeId');
    // this.claimGet();
    this.storeIdReferral();
  }

  ngOnInit(): void {
    if (environment.referralHost.includes(window.location.origin)) {
      this.hideHeader = true;
    }
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.store_id = localStorage.getItem('currentStore')

    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      // this.singleUserPoint(this.store.id, this.phoneNumber);
      // this.tierList();
      this.isLoading = false;
    });
    console.log('11111111111111111', window.location.pathname);


    // this.form = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   phone: ['', Validators.required],
    //   // phone: ['', Validators.required],
    //   email: ['', Validators.required]
    // });

    this.referralForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, this.phoneLengthValidator(10, 15)]],
      email: ['',  [Validators.required, Validators.email]]
    });

    this.formVali = this.formBuilder.group({
      phone: ['', Validators.required],
    });
    this.formOTP = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  phoneLengthValidator(minLength: number, maxLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value?.toString();
      if (!value) return null;
      if (value.length < minLength) {
        return { minlength: true };
      }
      if (value.length > maxLength) {
        return { maxlength: true };
      }
      return null;
    };
  }

  nextPage() {
    this.nextPageShowHide = '2';
  }
  firstBack() {
    this.nextPageShowHide = '';
    this.nextPageShowHide = '1';
  }
  secondBack() {
    this.nextPageShowHide = '';
    this.nextPageShowHide = '2';
  }

  storeIdReferral() {
    let keySend = {
      "referral_code": this.referralKey,
    }
    this.orderService.storeIdReferral(keySend).subscribe(data => {
      console.log('dddddddddddddddd', data);

      if (data.data) {
        this.storeId = data.store_id
        this.claimGet();
      }
    });
  }
  claimGet() {
    let keySend = {
      "store_id": this.storeId,
      "referral_promotion": this.queryParam1,
      "referee_by_referral_code": window.location.href + '/' + this.storeId
    }
    this.orderService.claimGet(keySend).subscribe(data => {
      this.getData = data.data[0];
      localStorage.setItem('referee_gets', JSON.stringify(this.getData));
      this.refereeGetsDollar = this.getData.referee_gets_dollar
      this.referrerGetsDollar = this.getData.referrer_gets_dollar
    });
  }

  focusNext(index: number) {
    if (index < this.otpValues.length) {
      const nextInput = this[`input${index + 1}`] as ElementRef;
      nextInput.nativeElement.focus();
    }
  }
  onLastInput() {
    this.enteredOTP = this.otpValues.join('');
    // this.verifyOTP(enteredOTP);
  }

  otpVerify() {
    if (this.formOTP.value.phone == '') {
      this.errorMessage = 'Input filed are required.';
      return;
    }
    let keySend = {
      "store_id": this.storeId,
      "otp": this.enteredOTP,
      "phone_number": '+1' + this.form.value.phone,
    }

    this.orderService.optNumberVerify(keySend).subscribe(data => {
      if (data.is_verified) {
        this.is_verified = data.is_verified
        this.ShowOPT = false
        // this.nextVerifyShowHide = false
        this.nextPageShowHide = '';
        this.nextPageShowHide = '3'
      }
      else {
        this.errorMessage2 = 'OTP verification failed. Please try again.';;
        return;
      }
    });
  }
  numberVerify() {
    // if (this.form.value.phone == '' || this.form.value.name == '' || this.form.value.email == '') {
    //   this.errorMessage1 = 'Input filed are required.';
    //   return;
    // }
    this.submitted = true;
    if (this.referralForm.invalid) {
      return;
    }
    let keySend = {
      "store_id": this.storeId,
      "phone_number": '+1' + this.referralForm.value.phone,
      "referral_code": `${location.origin}` + window.location.pathname + '/' + this.storeId,
      "referral_promotion": this.queryParam1
    }

    this.orderService.numberVerify(keySend).subscribe(data => {
      if (data.data == true) {
        this.ShowOPT = data.data;
        this.errorMessage1 = '';
        localStorage.setItem('mob', keySend.phone_number)
        localStorage.setItem('user_name-ref', this.referralForm.value.name)
        this.router.navigate([window.location.pathname + '/' + this.storeId + '/verification']);
        // this.secondBack();
        // this.nextVerifyShowHide = true
      }
      else {
        if (data.msg != '') {
          this.errorMessage1 = data.msg;
        }
        else {
          this.errorMessage1 = 'You have already claimed this referral offer';
        }
        return;
      }
    });
  }
  refereeRewardSubmit() {
    // if (this.form.value.name === '' || this.form.value.phone === '' || this.form.value.email) {
    //   this.errorMessage = 'Input filed are required.';
    //   return;
    // }
    // || this.form.value.email == ''
    // if (this.form.value.name == '' || this.form.value.phone === '') {
    //   this.errorMessage = 'Input filed are required.';
    //   return;
    // }
    let keySend = {
      "store_id": this.storeId,
      "referral_promotion": this.queryParam1,
      "referee_by_referral_code": window.location.href,
      "referee_name": this.form.value.name,
      "phone_number": '+1' + this.form.value.phone,
      // "phone_number": '+' + this.form.value.phone,
      "email": '',
      "link": `${location.origin}/store/${this.storeId}/referral/cashier-verification`,
    }

    this.orderService.claimForm(keySend).subscribe(data => {
      this.idGet = data.data.id;
      this.phoneNumber = data.data.phone_number
      console.log('assas', this.idGet);
      localStorage.setItem("phoneNumber", keySend.phone_number)
      localStorage.setItem("currentStore", this.storeId)
      this.router.navigate(['store/' + this.storeId + '/referral-menu']);

      // this.nextPage();
    });
  }
  redeemAPi() {
    let keySend = {
      "phone_number": this.phoneNumber,
      "referral_promotion": this.queryParam1,
      "idGet": this.idGet
    }
    // this.orderService.redeemAPi(this.hash, this.idGet, keySend).subscribe(data => {
    // this.createdAt = data.created_at;
    // this.redeemId = data.redeem_id;
    // this.redeemedAmount = data.redeemed_amount;

    // console.log('2222222222222222222', data);
    this.router.navigate([this.router.url + '/verification'],
      {
        state: { keySend }
      }
    );
    // });
  }

}
