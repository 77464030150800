import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-promotion-link',
  templateUrl: './referral-promotion-link.component.html',
  styleUrls: ['./referral-promotion-link.component.sass']
})
export class ReferralPromotionLinkComponent {
  store: any;
  storeId: any;
  isLoading = true;
  store_id: any;
  hash: any;
  phoneNumber: any;
  FilterPromot: any;
  default_msg: any;
  idSet: any;
  referralCode1: any;
  referralCode: any;
  referralCodeSharableLink: string;
  urlSlug: any;
  offerCatalogSharableLink: any;
  storeName: any;
  hideHeader: boolean = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  private ngNavigatorShareService: NgNavigatorShareService;

  ngOnInit(): void {
    if (environment.referralHost.includes(window.location.origin)) {
      this.hideHeader = true;
    }
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.store_id = localStorage.getItem('currentStore');
    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.storeName = store.url_slug;
      this.referralLinkData(this.storeName);
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }

      this.isLoading = false;
    });
    this.urlSlug = this.store.url_slug
  }
  goBack() {
    window.history.back()
  }

  referralLinkData(link) {
    let keySend = {
      "store_id": this.store_id,
      "phone_number": this.phoneNumber,
      "referral_promotion_id": this.FilterPromot.id

    }
    this.orderService.referralLinkData(keySend).subscribe(data => {
      this.idSet = data.data[0].id;
      this.default_msg = data.default_msg;
      this.referralCode = data.data[0].referral_code;
      this.referralCodeSharableLink = `${environment.referralPinvrf}/${this.referralCode}/${this.FilterPromot.id}`;
      this.referralLink(this.referralCodeSharableLink);
    })
  }


  referralLink(link1) {
    let keySend = {
      "url": link1,
    }

    this.orderService.referralLink(keySend).subscribe(data => {
      this.referralCode1 = data.data
      console.log('Shortened URL:', data.data);
    });
  }


  copyMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Copied', "OK", {
      duration: 5000,
      verticalPosition: 'bottom'
    })
  }

  shareHigth(offer) {
    console.log(offer);

    console.log(this.router.url);
    let url = this.referralCodeSharableLink;
    
    if (!this.ngNavigatorShareService.canShare()) {
      this.copyMessage(url);
      return;
    }

    this.ngNavigatorShareService.share({
      title: '',
      text: this.default_msg + '\n\n',
      url: `${this.referralCode}/${this.FilterPromot.id}`,
    }).then((response) => {
      console.log(response);
    })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
    // }
  }


}
